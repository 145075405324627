import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import ErrorNotification from "./ErrorNotification";
import SuccessNotification from "./SuccessNotification";
import { RichTextEditor } from "@mantine/rte";

const ManufacturingForm = () => {
  const [otherUsesTitle, setOtherUsesTitle] = useState("");
  const [otherUsesContent, setOtherUsesContent] = useState("");
  const [otherBenefitsTitle, setOtherBenefitsTitle] = useState("");
  const [otherBenefitsContent, setOtherBenefitsContent] = useState("");
  const [moreInfoTitle, setMoreInfoTitle] = useState("");
  const [moreInfoContent, setMoreInfoContent] = useState("");
  const [processImg, setProcessImg] = useState("");
  const [value, onChange] = useState("");
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const [applicationsNumber, setApplicationsNumber] = useState(0);
  const [guidelinesNumber, setGuidelinesNumber] = useState(0);
  const [rowsNumber, setRowsNumber] = useState({});
  const [defTableRowsNumber, setDefTableRowsNumber] = useState({});
  const [guidelineOption, setGuidelineOption] = useState([""]);
  const [comparisonTitle, setComparisonTitle] = useState("");
  const [materialOptionsNumber, setMaterialOptionsNumber] = useState(0);
  const [comparisonHeaderNumber, setComparisonHeaderNumber] = useState(0);
  const [comparisonRowsNumber, setComparisonRowsNumber] = useState(0);
  const [comparisonCols, setComparisonCols] = useState([]);
  const [comparisonFields, setComparisonFields] = useState([]);
  const [comparisonTable, setComparisonTable] = useState([[]]);
  const [processName, setProcessName] = useState("");
  const [processEntry, setProcessEntry] = useState("");
  const [processSub, setProcessSub] = useState("");
  const [applications, setApplications] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoContent, setVideoContent] = useState("");
  const [guideSection, setGuideSection] = useState("");
  const [guideDescription, setGuideDescription] = useState("");
  const [metricsName, setMetricsName] = useState([]);
  const [metricsHeader, setMetricsHeader] = useState([]);
  const [metricsContent, setMetricsContent] = useState([]);
  const [materialOptionsTitle, setMaterialOptionsTitle] = useState("");
  const [optionsName, setOptionsName] = useState([]);
  const [optionsHeader, setOptionsHeader] = useState([]);
  const [optionsContent, setOptionsContent] = useState([]);
  const [descriptionTitle, setDescriptionTitle] = useState([]);
  const [descriptionText, setDescriptionText] = useState([]);
  const [guidelinesContent, setGuidelinesContent] = useState([
    {
      name: "",
      text: "",
      type: "",
      title: "",
      headerCol: [],
      colContent: [],
    },
  ]);
  const [materialsTitle, setMaterialsTitle] = useState([]);
  const [materialsContent, setMaterialsContent] = useState([]);
  const [materialsBenefits, setMaterialsBenefits] = useState([]);
  const [entryImg, setEntryImg] = useState([]);

  useEffect(() => {
    document.title = "Agregar nuevo proceso de manufactura";
  }, []);

  const handleClose = (closeStatus) => {
    setVisible(closeStatus);
  };

  const applicationsInput = () => {
    const applications = [];
    for (let i = 0; i < applicationsNumber; i++) {
      applications.push(
        <input
          type="text"
          className="inputBox"
          onChange={(e) => addApplications(e, e.target.name)}
          placeholder="good surface quality for cosmetic prototypes"
          name={i}
          required
        />
      );
    }
    return applications;
  };

  const guidelinesDescriptionInput = (index) => {
    return (
      <div>
        <span className="labelContent">Titulo</span>
        <input
          type="text"
          className="inputBox"
          placeholder="SLA Tolerances"
          onChange={(e) => addDescription(e, e.target.name, index, "title")}
          name={index}
        />
        <span className="labelContent">Descripción</span>
        <textarea
          className="inputBox"
          name={index}
          id=""
          cols="30"
          rows="5"
          onChange={(e) => addDescription(e, e.target.name, index, "text")}
          placeholder="For well-designed parts, tolerances in the X/Y dimension of ±0.002 in. (0.05mm) for first inch plus ±0.001 in./in. (0.001mm/mm), and Z dimension tolerances of ±0.005 in. for first inch plus ±0.001 in./in. (0.001mm/mm), can typically be achieved. Note that tolerances may change depending on part geometry."
        ></textarea>
      </div>
    );
  };
  const guidelinesMetricsTableInput = (index) => {
    const rows = [];
    for (let i = 0; i < rowsNumber[index]; i++) {
      rows.push(
        <div>
          <span className="labelContent">{`Renglon ${i}`}</span>
          <input
            type="text"
            name={i}
            className="inputBox"
            placeholder="Normal Resolution (Col 1)"
            required
            onChange={(e) => addMetricTable(e, e.target.name, index, "col1")}
          />
          <input
            type="text"
            name={i}
            className="inputBox"
            placeholder="736mm x 635mm x 533mm (Col 2)"
            onChange={(e) => addMetricTable(e, e.target.name, index, "col2")}
            required
          />
        </div>
      );
    }

    return (
      <div>
        <span className="labelContent">Nombre</span>
        <input
          type="text"
          name={index}
          className="inputBox"
          onChange={(e) => {
            addMetricName(e, index);
          }}
          placeholder="Max part size"
          required
        />
        <span className="labelContent">Cantidad de renglones</span>
        <input
          type="number"
          className="inputBox"
          placeholder="1"
          required
          name={index}
          onChange={(e) =>
            setRowsNumber({ ...rowsNumber, [e.target.name]: e.target.value })
          }
        />
        {rows}
      </div>
    );
  };

  const guidelinesDefTableInput = (index) => {
    const rows = [];
    for (let i = 0; i < defTableRowsNumber[index]; i++) {
      rows.push(
        <div>
          <span className="labelContent">{`Renglon ${i}`}</span>
          <input
            type="text"
            name={i}
            className="inputBox"
            placeholder="Unfinished"
            required
            onChange={(e) => addDefTable(e, e.target.name, index, "col1")}
          />
          <input
            type="text"
            name={i}
            className="inputBox"
            onChange={(e) => addDefTable(e, e.target.name, index, "col2")}
            placeholder="Dots, or standing nibs remain evident on the bottom of the part from the support structure remnants."
            required
          />
        </div>
      );
    }
    return (
      <div>
        <input
          type="text"
          name={index}
          className="inputBox"
          onChange={(e) => {
            addOptionsName(e, index);
          }}
          placeholder="Max part size"
          required
        />
        <span className="labelContent">Cantidad de renglones</span>
        <input
          type="number"
          name={index}
          className="inputBox"
          placeholder="1"
          required
          onChange={(e) => {
            setDefTableRowsNumber({
              ...defTableRowsNumber,
              [e.target.name]: e.target.value,
            });
          }}
        />
        {rows}
      </div>
    );
  };

  const guidelinesInput = () => {
    const guidelines = [];
    for (let i = 0; i < guidelinesNumber; i++) {
      guidelines.push(
        <div className="pb-5 text-sm">
          <span className="labelContent">Tipo de directriz</span>
          <select
            name={i}
            onChange={(event) => {
              addSelection(event, event.target.name);
            }}
          >
            <option value="METRIC_TABLE">Tabla con metricas</option>
            <option value="OPTION_TABLE">Tabla con definiciones</option>
            <option value="DESCRIPTION">Descripción de la directriz</option>
          </select>
          {guidelineOption[i] === "METRIC_TABLE"
            ? guidelinesMetricsTableInput(i)
            : guidelineOption[i] === "OPTION_TABLE"
            ? guidelinesDefTableInput(i)
            : guidelineOption[i] === "DESCRIPTION"
            ? guidelinesDescriptionInput(i)
            : ""}
        </div>
      );
    }
    return guidelines;
  };

  const materialOptionsInput = () => {
    const options = [];
    for (let i = 0; i < materialOptionsNumber; i++) {
      options.push(
        <div className="pb-8">
          <span className="labelContent">Titulo</span>
          <input
            type="text"
            name={i}
            onChange={(e) => {
              addMaterialsTitle(e, e.target.name);
            }}
            className="inputBox"
            placeholder="ABS-Like White (Accura Xtreme White 200)"
            required
          />
          <span className="labelContent">Contenido</span>
          <textarea
            type="text"
            name={i}
            onChange={(e) => {
              addMaterialsContent(e, e.target.name);
            }}
            className="inputBox"
            rows="5"
            required
            placeholder="ABS-Like White (Accura Xtreme White 200) is a widely used general purpose SLA material. In terms of flexibility and strength, this material falls between molded polypropylene and molded ABS, which makes it a good choice for functional prototypes. Parts as large as 29 in. x 25 in. x 21 in. can be built with ABS-Like White so consider it a primary option if you require an extensive part size build envelope."
          ></textarea>
          <span className="labelContent">Beneficios</span>
          <input
            type="text"
            name={i}
            className="inputBox"
            onChange={(e) => {
              addMaterialsBenefits(e, e.target.name, "benefit 1");
            }}
            required
            placeholder="Durable, general purpose resin"
          />
          <input
            type="text"
            name={i}
            id=""
            className="inputBox"
            onChange={(e) => {
              addMaterialsBenefits(e, e.target.name, "benefit 2");
            }}
            required
            placeholder="Accomodates extra-large parts"
          />
        </div>
      );
    }

    return options;
  };

  const comparisonRowsInput = () => {
    const cols = comparisonFieldInput();
    const rows = cols.map((item) => (
      <div className="flex flex-row">{item.map((fields) => fields)}</div>
    ));

    return rows;
  };

  const comparisonFieldInput = () => {
    const columns = [];
    let counter = 0;
    for (let i = 0; i < comparisonRowsNumber; i++) {
      columns[i] = new Array(2);
    }

    for (let i = 0; i < comparisonRowsNumber; i++) {
      for (let j = 0; j < comparisonHeaderNumber; j++) {
        columns[i][j] = (
          <input
            type="text"
            className="inputBox"
            name={counter}
            onChange={(e) => addComparisonInputs(e, e.target.name)}
          />
        );
        counter++;
      }
    }

    return columns;
  };

  const comparisonTableInput = () => {
    const headerCols = [];
    for (let i = 0; i < comparisonHeaderNumber; i++) {
      headerCols.push(
        <div className="mx-1">
          <input
            type="text"
            className="inputBox"
            placeholder="Material, Color, Tensile Strength, Tensile Modulus, Elongation, etc"
            name={i}
            required
            onChange={(e) => addComparisonCols(e, e.target.name)}
          />
        </div>
      );
    }
    return (
      <div className="mb-5">
        <span className="labelContent">
          Cantidad de columnas para el header de la tabla
        </span>
        <input
          type="number"
          name=""
          id=""
          className="inputBox"
          placeholder="2"
          onChange={(e) => setComparisonHeaderNumber(e.target.value)}
          required
        />
        <div className="flex flex-row">{headerCols}</div>
        <span className="labelContent">
          Cantidad de renglones para definir el contenido la tabla
        </span>
        <input
          type="number"
          name=""
          id=""
          className="inputBox"
          placeholder="5"
          onChange={(e) => setComparisonRowsNumber(e.target.value)}
          required
        />
        {comparisonRowsInput()}
      </div>
    );
  };

  const addComparisonInputs = (event, index) => {
    let counter = 0;
    const inputs = [...comparisonFields];
    inputs[index] = event.target.value;
    setComparisonFields(inputs);

    let fieldsInRowArr = [...comparisonTable];

    for (let i = 0; i < comparisonRowsNumber; i++) {
      for (let j = 0; j < comparisonHeaderNumber; j++) {
        if (fieldsInRowArr[i] === undefined) fieldsInRowArr[i] = new Array(2);
        else fieldsInRowArr[i][j] = inputs[counter];
        counter++;
      }
    }

    setComparisonTable(fieldsInRowArr);
  };

  const addComparisonCols = (event, index) => {
    const cols = [...comparisonCols];
    cols[index] = event.target.value;
    setComparisonCols(cols);
  };

  const addSelection = (event, index) => {
    let selection = [...guidelineOption];
    selection[index] = event.target.value;
    setGuidelineOption(selection);
  };

  const addMaterialsTitle = (event, index) => {
    let titles = [...materialsTitle];
    titles[index] = event.target.value;
    setMaterialsTitle(titles);
  };
  const addMaterialsContent = (event, index) => {
    let content = [...materialsContent];
    content[index] = event.target.value;
    setMaterialsContent(content);
  };

  const addMaterialsBenefits = (event, index, type) => {
    let benefits = [...materialsBenefits];

    if (benefits[index] === undefined) {
      benefits[index] = [];
    }

    if (type === "benefit 1") {
      benefits[index][0] = event.target.value;
      setMaterialsBenefits(benefits);
    } else if (type === "benefit 2") {
      benefits[index][1] = event.target.value;
      setMaterialsBenefits(benefits);
    }
  };

  const addApplications = (event, index) => {
    let apps = [...applications];
    apps[index] = event.target.value;
    setApplications(apps);
  };

  const addMetricName = (event, index) => {
    let name = [...metricsName];
    name[index] = event.target.value;
    setMetricsName(name);
  };
  const addOptionsName = (event, index) => {
    let name = [...optionsName];
    name[index] = event.target.value;
    setOptionsName(name);
  };

  const addMetricTable = (event, metricIndex, guidelineIndex, type) => {
    let nameArr = [...metricsName];
    let headerArr = [...metricsHeader];
    let contentArr = [...metricsContent];

    if (type === "col1") {
      headerArr[metricIndex] = event.target.value;
      setMetricsHeader(headerArr);
    }

    if (type === "col2") {
      contentArr[metricIndex] = event.target.value;
      setMetricsContent(contentArr);
    }

    let metricTableArr = [...guidelinesContent];
    if (metricTableArr[guidelineIndex] === undefined) {
      metricTableArr.push({
        name: nameArr[guidelineIndex],
        text: "",
        type: "METRIC_TABLE",
        title: "",
        headerCol: headerArr,
        colContent: contentArr,
      });
    } else {
      metricTableArr[guidelineIndex]["name"] = nameArr[guidelineIndex];
      metricTableArr[guidelineIndex]["type"] = "METRIC_TABLE";
      metricTableArr[guidelineIndex]["text"] = "";
      metricTableArr[guidelineIndex]["title"] = "";
      metricTableArr[guidelineIndex]["headerCol"] = headerArr;
      metricTableArr[guidelineIndex]["colContent"] = contentArr;
    }
    setGuidelinesContent(metricTableArr);
  };

  const addDefTable = (event, descIndex, guidelineIndex, col) => {
    let nameArr = [...optionsName];
    let headerArr = [...optionsHeader];
    let contentArr = [...optionsContent];
    if (col === "col1") {
      headerArr[descIndex] = event.target.value;
      setOptionsHeader(headerArr);
    }

    if (col === "col2") {
      contentArr[descIndex] = event.target.value;
      setOptionsContent(contentArr);
    }

    let optionsTableArr = [...guidelinesContent];
    if (optionsTableArr[guidelineIndex] === undefined) {
      optionsTableArr.push({
        name: nameArr[guidelineIndex],
        text: "",
        type: "OPTIONS_TABLE",
        title: "",
        headerCol: headerArr,
        colContent: contentArr,
      });
    } else {
      optionsTableArr[guidelineIndex]["name"] = nameArr[guidelineIndex];
      optionsTableArr[guidelineIndex]["title"] = "";
      optionsTableArr[guidelineIndex]["type"] = "OPTIONS_TABLE";
      optionsTableArr[guidelineIndex]["text"] = "";
      optionsTableArr[guidelineIndex]["headerCol"] = headerArr;
      optionsTableArr[guidelineIndex]["colContent"] = contentArr;
    }
    setGuidelinesContent(optionsTableArr);
  };

  const addDescription = (event, descIndex, guidelineIndex, type) => {
    let nameArr = [...optionsName];
    let titleArr = [...descriptionTitle];
    let textArr = [...descriptionText];
    if (type === "title") {
      titleArr[descIndex] = event.target.value;
      setDescriptionTitle(titleArr);
    }

    if (type === "text") {
      textArr[descIndex] = event.target.value;
      setDescriptionText(textArr);
    }

    let descriptionArr = [...guidelinesContent];
    if (descriptionArr[guidelineIndex] === undefined) {
      descriptionArr.push({
        name: titleArr[guidelineIndex],
        text: textArr[guidelineIndex],
        type: "DESCRIPTION",
        title: titleArr[guidelineIndex],
        headerCol: "",
        colContent: "",
      });
    } else {
      descriptionArr[guidelineIndex]["name"] = titleArr[guidelineIndex];
      descriptionArr[guidelineIndex]["title"] = titleArr[guidelineIndex];
      descriptionArr[guidelineIndex]["type"] = "DESCRIPTION";
      descriptionArr[guidelineIndex]["text"] = textArr[guidelineIndex];
      descriptionArr[guidelineIndex]["headerCol"] = "";
      descriptionArr[guidelineIndex]["colContent"] = "";
    }
    setGuidelinesContent(descriptionArr);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { data: manufacturing, error: manufacturing_error } = await supabase
      .from("manufacturing")
      .insert([
        {
          name: processName,
          img: processImg,
          entry: processEntry,
          entryImg: entryImg,
          content: value,
          applications: applications,
          videoTitle: videoTitle,
          video: videoUrl,
          videoText: videoContent,
          guidelineTitle: guideSection,
          guidelinesText: guideDescription,
          guidelinesSections: guidelinesContent,
          optionsTitle: materialOptionsTitle,
          typeTitle: materialsTitle,
          typeText: materialsContent,
          typeBenefitsList: materialsBenefits,
          comparisonTitle: comparisonTitle,
          comparisonTH: comparisonCols,
          comparisonTB: comparisonTable,
          other_benefits_title: otherBenefitsTitle,
          other_benefits_content: otherBenefitsContent,
          other_uses_title: otherUsesTitle,
          other_uses_content: otherUsesContent,
          more_info_title: moreInfoTitle,
          more_info_content: moreInfoContent,
        },
      ]);

    if (!manufacturing_error) {
      setError(false);
      setNotification({
        title: "Éxito",
        message: "El proceso de manufactura se publicó con éxito.",
      });
      setVisible(true);
      navigate("/manufacturing");
    } else {
      setError(true);
      setNotification({
        title: "Fallo",
        message: `El proceso de manufactura no se ha podido publicar. Intentalo de nuevo. Mensaje de error: ${manufacturing_error.message}`,
      });
      setVisible(true);
    }
  };

  return (
    <>
      {error && visible ? (
        <ErrorNotification
          title={notification.title}
          message={notification.message}
          onSaveVisibleStatus={handleClose}
          visible={visible}
        />
      ) : (
        !error &&
        visible && (
          <SuccessNotification
            title={notification.title}
            message={notification.message}
            onSaveVisibleStatus={handleClose}
            visible={visible}
          />
        )
      )}
      <div className={`font-Poppins w-full`}>
        <div className={`bg-blue-600 w-full p-32 text-white`}>
          <h2 className="text-4xl font-semibold text-center">
            Agregar nuevo proceso de manufactura
          </h2>
        </div>
        <div className={`container container-m-p shadow-lg rounded-lg`}>
          <form
            className="px-32 w-full flex flex-col"
            onSubmit={(event) => handleSubmit(event)}
          >
            <div className="flex flex-col">
              <div>
                <span className="labelContent">
                  Nombre del proceso de manufactura
                </span>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Stereolithography (SLA)"
                  required
                  onChange={(e) => setProcessName(e.target.value)}
                />
                <span className="labelContent">
                  Imagen del proceso de manufactura
                </span>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="https://www.protolabs.co.uk/media/1013213/stereolithography-3d-printing-process-cte-large.png"
                  required
                  onChange={(e) => setProcessImg(e.target.value)}
                />
                <span className="labelContent">Entrada del artículo</span>
                <RichTextEditor
                  value={processEntry}
                  onChange={setProcessEntry}
                  className="w-full mb-5"
                />
                <span className="labelContent">Contenido del artículo</span>
                <RichTextEditor
                  value={value}
                  onChange={onChange}
                  className="w-full mb-5"
                />
                <span className="labelContent">Imagen del artículo</span>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="https://via.placeholder.com/600x600"
                  onChange={(e) => setEntryImg(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <span className="labelContent">Cantidad de aplicaciones</span>
                <input
                  type="number"
                  name="applications"
                  className="inputBox"
                  onChange={(event) =>
                    setApplicationsNumber(event.target.value)
                  }
                />
                {applicationsInput()}
              </div>
              <div className="mt-5">
                <h3 className="font-medium text-xl">Sección con vídeo</h3>
                <span className="labelContent">URL del video</span>
                <input
                  type="text"
                  className="inputBox"
                  onChange={(e) => setVideoUrl(e.target.value)}
                  placeholder=""
                  required
                />
                <span className="labelContent">
                  Título de la sección con vídeo
                </span>
                <input
                  type="text"
                  className="inputBox"
                  onChange={(e) => setVideoTitle(e.target.value)}
                  placeholder="Why Use SLA?"
                  required
                />
                <span className="labelContent">
                  Contenido de la sección con vídeo
                </span>
                <textarea
                  name=""
                  id=""
                  cols="10"
                  rows="3"
                  onChange={(e) => setVideoContent(e.target.value)}
                  className="inputBox"
                  placeholder="Stereolithography (SLA) is an additive manufacturing process that can 3D print parts with small features, tight tolerance requirements, and smooth surface finishes."
                ></textarea>
              </div>
              <div className="mt-5">
                <div className="font-medium text-xl">
                  Sección de directrices y capacidades de diseño
                </div>
                <span className="labelContent">Titulo de la sección</span>
                <input
                  type="text"
                  className="inputBox"
                  required
                  onChange={(e) => setGuideSection(e.target.value)}
                  placeholder="Design Guidelines and Capabilities"
                />
                <span className="labelContent">
                  Cantidad de directrices y capacidades
                </span>
                <input
                  type="number"
                  name="guidelinesNumber"
                  className="inputBox"
                  required
                  onChange={(event) => setGuidelinesNumber(event.target.value)}
                />
                {guidelinesInput()}
              </div>
              <div className="mt-5">
                <h3 className="font-medium text-xl">Opciones del material</h3>
                <span className="labelContent">Titulo de la sección</span>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="SLA Material Options"
                  onChange={(event) =>
                    setMaterialOptionsTitle(event.target.value)
                  }
                  required
                />
                <span className="labelContent">Cantidad de opciones</span>
                <input
                  type="number"
                  name=""
                  id=""
                  className="inputBox"
                  placeholder="1"
                  required
                  onChange={(e) => {
                    setMaterialOptionsNumber(e.target.value);
                  }}
                />
                {materialOptionsInput()}
              </div>
              <div className="mt-5">
                <div className="font-medium text-xl">
                  Sección de comparación de las propiedades del material
                </div>
                <span className="labelContent">Titulo</span>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Compare Carbon DLS Material Properties"
                  required
                  onChange={(e) => setComparisonTitle(e.target.value)}
                />
                <span className="labelContent">Tabla</span>
                {comparisonTableInput()}
              </div>
              <h2 className="font-medium text-xl w-full">Beneficios y usos</h2>
              <div className="mt-5 flex flex-row w-full">
                <div className="flex flex-col w-1/2 ml-1">
                  <span className="labelContent">Titulo</span>
                  <input
                    type="text"
                    className="inputBox"
                    placeholder="Benefits of Stereolitography"
                    required
                    onChange={(e) => setOtherBenefitsTitle(e.target.value)}
                  />
                  <span className="labelContent">Contenido</span>
                  <RichTextEditor
                    value={otherBenefitsContent}
                    onChange={setOtherBenefitsContent}
                  />
                </div>
                <div className="flex flex-col w-1/2 mr-1">
                  <span className="labelContent">Titulo</span>
                  <input
                    type="text"
                    className="inputBox"
                    placeholder="What is Stereolithography Used for?"
                    required
                    onChange={(e) => setOtherUsesTitle(e.target.value)}
                  />
                  <span className="labelContent">Contenido</span>
                  <RichTextEditor
                    value={otherUsesContent}
                    onChange={setOtherUsesContent}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-xl mt-3 mb-1">
                  Más información y consideraciones
                </span>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="What are the material options for Stereolithography?"
                  required
                  onChange={(e) => setMoreInfoTitle(e.target.value)}
                />
                <RichTextEditor
                  value={moreInfoContent}
                  onChange={setMoreInfoContent}
                />
              </div>
            </div>
            <button
              type="submit"
              className="rounded-full bg-indigo-500 text-white py-2 px-5 mt-12 hover:bg-indigo-700 transition shadow-lg shadow-indigo-500/50"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ManufacturingForm;
