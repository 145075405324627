import React from "react";
import { useFormContext } from "react-hook-form";
import { useForm, useFieldArray } from "react-hook-form";
import FeatureInput from "./FeatureInput";
import ImageInput from "./ImageInput";
import MetricsInput from "./MetricsInput";

const FamilyForm = () => {
  const { register, control } = useFormContext();
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "materials",
    name: "materials_in_family",
  });
  return (
    <div id="family_form">
      <h1 className="text-2xl font-semibold">Materiales en la familia</h1>
      <ul>
        {fields.map((item, index) => {
          return (
            <div className="flex flex-col mb-1" key={item.id}>
              <div className="flex flex-row items-center">
                <h2 className="font-semibold text-lg basis-3/4">
                  Material {index + 1}
                </h2>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="basis-1/4 w-fit mb-2 rounded-lg px-4 py-2 bg-red-500 text-white hover:bg-red-600 duration-300"
                >
                  Remover material
                </button>
              </div>
              <li className="flex flex-row">
                <input
                  {...register(`materials_in_family.${index}.title`)}
                  className="inputBox"
                  placeholder="Titulo"
                />
                <input
                  {...register(`materials_in_family.${index}.subtitle`)}
                  className="inputBox"
                  placeholder="Subtitulo"
                />
              </li>
              <div className="flex flex-col">
                <input
                  {...register(`materials_in_family.${index}.img`)}
                  className="inputBox"
                  placeholder="Imagen"
                />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  {...register(`materials_in_family.${index}.description`)}
                  className="inputBox"
                  placeholder="Descripcion"
                ></textarea>
                <FeatureInput index={index} name={`feature${index}`} />
                <MetricsInput index={index} name={`metric${index}`} />
                <ImageInput index={index} name={`image${index}`} />
              </div>
            </div>
          );
        })}
      </ul>
      <section>
        <button
          type="button"
          className="rounded-lg px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 duration-300 w-full"
          onClick={() => {
            append({});
          }}
        >
          + Agregar material
        </button>
      </section>
    </div>
  );
};

export default FamilyForm;
