import React, { useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { supabase } from "../supabaseClient";
import FamilyForm from "./FamilyForm";
import SpecificationsForm from "./SpecificationsForm";
import ApplicationsForm from "./ApplicationsForm";
import ListForm from "./ListForm";
import ErrorNotification from "./ErrorNotification";
import SuccessNotification from "./SuccessNotification";
const MaterialsForm = () => {
  const [colors, setColors] = useState([""]);
  const methods = useForm();
  const { register, handleSubmit, unregister } = methods;
  const featuredImgRef = useRef(null);
  const { v4: uuidv4 } = require("uuid");
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
  });

  const onSubmit = async (data) => {
    if (
      data.materials_in_family.length === 0 ||
      data.specifications.length === 0 ||
      data.featuredApplications.list.length === 0 ||
      data.featuredApplications.featuredImg === "" ||
      data.applications.length === 0
    ) {
      alert("Invalid input");
    } else {
      const newName = uuidv4();
      const featuredImg = featuredImgRef.current.files[0];
      const extension = featuredImg.name.split(".").pop();
      const newFileName = `featured_${newName}.${extension}`;
      const { img_data, img_error } = await supabase.storage
        .from("materials")
        .upload(`images/${newFileName}`, featuredImg, {
          cacheControl: "3600",
          upsert: false,
        });
      const { publicURL, url_error } = supabase.storage
        .from("materials")
        .getPublicUrl(`images/${newFileName}`);

      const { data: materials_data, error: materials_error } = await supabase
        .from("materials")
        .insert([
          {
            name: data.title,
            description: data.description,
            img: publicURL,
            colors: data.colors,
            family: data.materials_in_family,
            specifications: data.specifications,
            applications: data.applications,
            featuredApplications: data.featuredApplications,
          },
        ]);

      if (materials_error === null) {
        setError(false);
        setNotification({
          title: "Éxito",
          message: "El material se publicó con éxito.",
        });
        setVisible(true);
      } else {
        setError(true);
        setNotification({
          title: "Fallo",
          message: `El material no se ha podido publicar. Intentalo de nuevo. Mensaje de error: ${materials_error.message}`,
        });
        setVisible(true);
      }
    }
  };

  const handleColors = (e) => {
    unregister("colors");
    const newArray = new Array(parseInt(e.target.value)).fill("#ffffff");
    setColors(newArray);
  };

  const handleClose = (closeStatus) => {
    setVisible(closeStatus);
  };

  return (
    <FormProvider {...methods}>
      {error && visible ? (
        <ErrorNotification
          title={notification.title}
          message={notification.message}
          onSaveVisibleStatus={handleClose}
          visible={visible}
        />
      ) : (
        !error &&
        visible && (
          <SuccessNotification
            title={notification.title}
            message={notification.message}
            onSaveVisibleStatus={handleClose}
            visible={visible}
          />
        )
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col mx-12 mt-5"
      >
        <div id="material">
          <h1 className="text-4xl text-center font-semibold mb-10">
            Agregar nuevo material
          </h1>
          <input
            type="text"
            {...register(`title`)}
            className="inputBox"
            placeholder="Titulo"
            required
          />
          <input
            type="file"
            className="inputBox"
            placeholder="Imagen"
            ref={featuredImgRef}
            required
          />
          <input
            type="text"
            {...register(`description`)}
            className="inputBox"
            placeholder="Descripcion"
          />
          <label htmlFor="colors" className="labelContent">
            Cantidad de colores
          </label>
          <input
            type="number"
            onChange={handleColors}
            className="inputBox"
            placeholder="1"
          />
          <div className="flex flex-row mb-5">
            {colors.map((color, index) => (
              <input
                key={index}
                type="color"
                {...register(`colors.${index}`)}
                className="h-12 w-12 m-1"
              />
            ))}
          </div>
        </div>
        <div id="family" className="mb-10">
          <FamilyForm />
        </div>
        <div id="specifications" className="mb-10">
          <SpecificationsForm />
        </div>
        <div className="applications">
          <h1 className="text-2xl font-semibold mb-2">Aplicaciones</h1>
          <h1 className="text-xl font-semibold">Entrada destacada</h1>
          <input
            type="text"
            {...register(`featuredApplications.featuredImg`)}
            className="inputBox"
            placeholder="Imagen destacada"
          />
          <input
            type="text"
            {...register(`featuredApplications.featuredText`)}
            className="inputBox"
            placeholder="Contenido destacado"
          />
          <ListForm />
        </div>
        <ApplicationsForm />
        <input
          type="submit"
          className="my-10 rounded-sm px-4 py-4 bg-green-500 text-white hover:bg-green-600 duration-300 w-full"
        />
      </form>
    </FormProvider>
  );
};

export default MaterialsForm;
