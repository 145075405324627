import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
const ManageCategories = () => {
  const [categories, setCategories] = useState("");
  const [counter, setCounter] = useState(0);
  const fetchCategories = async () => {
    const { data, error } = await supabase.from("blog_categories").select("*");
    setCategories(data);
  };

  const handleDelete = async (id) => {
    const { data, error } = await supabase
      .from("blog_categories")
      .delete()
      .match({ id: id });
    fetchCategories();
  };

  const categoriesInputList =
    categories &&
    categories.map((category) => (
      <div className="flex flex-col" key={category.id}>
        <div className="flex flex-row">
          <input
            type="text"
            defaultValue={category.value}
            className="inputBox"
          />
          <button
            className=" ml-2 mt-1 px-3 rounded-md bg-red-500 text-white hover:bg-red-700"
            onClick={() => handleDelete(category.id)}
          >
            X
          </button>
        </div>
      </div>
    ));
  const newCategories = () => {
    let categoryArr = [];
    for (let i = 0; i < counter; i++) {
      categoryArr.push(
        <div className="flex flex-col">
          <input type="text" placeholder="Categoria" className="inputBox" />
        </div>
      );
    }
    return categoryArr.map((category) => category);
  };

  useEffect(() => {
    newCategories();
  }, [counter]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="container m-10">
      <h2 className="font-semibold text-xl text-center">
        Administrar categorias
      </h2>
      <div className="categories">{categoriesInputList}</div>
      {newCategories()}
      <button
        className=" ml-2 my-1 px-3 py-1 rounded-md bg-blue-500 text-white hover:bg-blue-700"
        onClick={() => setCounter(counter + 1)}
      >
        Agregar categorias
      </button>
    </div>
  );
};

export default ManageCategories;
