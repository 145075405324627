import React from "react";
import { useFormContext } from "react-hook-form";
import { useForm, useFieldArray } from "react-hook-form";

const ImageInput = (props) => {
  const { register, control } = useFormContext();
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: props.name,
  });
  return (
    <div>
      <ul>
        {fields.map((item, index) => {
          return (
            <div className="flex flex-row mb-1" key={item.id}>
              <input
                {...register(
                  `materials_in_family.${props.index}.gallery.${index}`
                )}
                className="inputBox"
                placeholder="URL de la imagen de la galeria"
              />
              <button
                type="button"
                onClick={() => remove(index)}
                className="rounded-lg ml-2 px-4 py-2 bg-red-500 text-white hover:bg-red-600 duration-300 w-32"
              >
                Remover imagen
              </button>
            </div>
          );
        })}
      </ul>
      <section>
        <button
          type="button"
          className="rounded-lg px-4 py-2 mb-8 mt-1 bg-indigo-500 text-white hover:bg-indigo-600 duration-300 w-full"
          onClick={() => {
            append({});
          }}
        >
          Agregar imagen
        </button>
      </section>
    </div>
  );
};

export default ImageInput;
