import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { supabase } from '../supabaseClient'

const Materials = () => {
    const [materials, setMaterials] = useState({ loading: true, data: [] })

    useEffect(() => {
        fetchMaterials()
        document.title = "Materiales - 3D Factory MX"
    }, [])

    const fetchMaterials = async () => {
        let { data: materials } = await supabase
            .from('materials')
            .select(`*`)
        setMaterials({ loading: false, data: materials })
    }

    const handleDelete = async (id) => {
        const { data, error } = await supabase
            .from('materials')
            .delete()
            .match({ id: id })
        fetchMaterials()
    }

    return (
        <div className="bg-white p-8 rounded-md w-full">
            <div className=" flex items-center justify-between pb-6">
                <div>
                    <h2 className="text-gray-600 font-semibold text-2xl">Materiales</h2>
                    <span className="text-xs">Todos los materiales</span>
                </div>
                <div className="flex items-center justify-between">
                    <div className="lg:ml-40 ml-10 space-x-8">
                        <Link to="/materials/add"><button className="bg-indigo-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">Agregar nuevo material</button></Link>
                    </div>
                </div>
            </div>
            <div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    <th
                                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Nombre
                                    </th>
                                    <th
                                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Descripción
                                    </th>
                                    <th
                                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    </th>
                                    <th
                                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {materials.data.map((item, index) => {
                                    return <tr key={index}>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0 w-10 h-10">
                                                    <img className="w-full h-full rounded-full"
                                                        src={item.img}
                                                        alt="" />
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {item.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">{item.description}</p>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <Link to={`/materials/edit/${item.id}`}><button className="px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-700">Editar</button></Link>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <button className="px-4 py-2 rounded-md bg-red-500 text-white hover:bg-red-700" onClick={() => handleDelete(item.id)}>Eliminar</button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div
                            className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                            {/* <span className="text-xs xs:text-sm text-gray-900">
                                Mostrando 1 a 4 de 50 entradas
                            </span> */}
                            {/* <div className="inline-flex mt-2 xs:mt-0">
                                <button
                                    className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l">
                                    Anterior
                                </button>
                                &nbsp; &nbsp;
                                <button
                                    className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r">
                                    Siguiente
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Materials
