import React, { useState, useRef, useEffect } from "react";
import { RichTextEditor } from "@mantine/rte";
import { supabase } from "../supabaseClient";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ErrorNotification from "./ErrorNotification";
import SuccessNotification from "./SuccessNotification";

const EditBlog = () => {
  const { id } = useParams();
  const titleRef = useRef("");
  const descriptionRef = useRef(null);
  const featuredImgRef = useRef(null);
  const { v4: uuidv4 } = require("uuid");
  const [value, onChange] = useState("");
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [postData, setPostData] = useState({});
  const [postCategories, setPostCategories] = useState([]);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
  });

  const fetchCategories = async () => {
    const { data, error } = await supabase.from("blog_categories").select("*");
    setAllCategories(data);
  };

  const fetchPost = async () => {
    const { data, error } = await supabase
      .from("blog")
      .select("*")
      .eq("id", id);
    setPostData(data[0]);
    setPostCategories(data[0].categories);
    onChange(data[0].content);
  };

  const defaultValue = postData.categories;
  useEffect(() => {
    fetchPost();
    fetchCategories();
  }, []);

  const handleCategoryChange = (value) => {
    setPostCategories(value);
  };

  const handleImageUpload = (file) =>
    new Promise(async (resolve, reject) => {
      const newName = uuidv4();
      const extension = file.name.split(".").pop();
      const newFileName = `${newName}.${extension}`;
      const { data, error } = await supabase.storage
        .from("blog")
        .upload(`images/${newFileName}`, file, {
          cacheControl: "3600",
          upsert: false,
        });
      const { publicURL, url_error } = supabase.storage
        .from("blog")
        .getPublicUrl(`images/${newFileName}`);
      resolve(publicURL);
    });

  const renderSelect = (categories) => {
    if (categories === undefined) return;

    return (
      <Select
        options={allCategories}
        name="categories"
        isMulti
        className="mb-10"
        placeholder="Seleccionar categorias"
        onChange={handleCategoryChange}
        defaultValue={categories}
      />
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let today = new Date();
    today = today.toLocaleString("es-MX", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    const newName = uuidv4();
    if (featuredImgRef.current.files[0] !== undefined) {
      const featuredImg = featuredImgRef.current.files[0];
      const extension = featuredImg.name.split(".").pop();
      const newFileName = `featured_${newName}.${extension}`;
      const { img_data, img_error } = await supabase.storage
        .from("blog")
        .upload(`images/${newFileName}`, featuredImg, {
          cacheControl: "3600",
          upsert: false,
        });
      const { publicURL, url_error } = supabase.storage
        .from("blog")
        .getPublicUrl(`images/${newFileName}`);

      const { data, error } = await supabase
        .from("blog")
        .update({
          title: titleRef.current.value,
          description: descriptionRef.current.value,
          content: value,
          categories: postCategories,
          editDate: today,
          entry_img: publicURL,
        })
        .match({ id: id });
      if (error === null) {
        setError(false);
        setNotification({
          title: "Éxito",
          message: "Tu artículo se editó con éxito.",
        });
        setVisible(true);
      } else {
        setError(true);
        setNotification({
          title: "Fallo",
          message: `Tu artículo no se ha podido editar. Intentalo de nuevo. Mensaje de error: ${error.message}`,
        });
        setVisible(true);
      }
    } else {
      const { data, error } = await supabase
        .from("blog")
        .update({
          title: titleRef.current.value,
          description: descriptionRef.current.value,
          content: value,
          categories: postCategories,
          editDate: today,
        })
        .match({ id: id });
      if (error === null) {
        setError(false);
        setNotification({
          title: "Éxito",
          message: "Tu artículo se modificó con éxito.",
        });
        setVisible(true);
      } else {
        setError(true);
        setNotification({
          title: "Fallo",
          message: `Tu artículo no se ha podido editar. Intentalo de nuevo. Mensaje de error: ${error.message}`,
        });
        setVisible(true);
      }
    }
  };
  const handleClose = (closeStatus) => {
    setVisible(closeStatus);
  };

  return (
    <>
      {error && visible ? (
        <ErrorNotification
          title={notification.title}
          message={notification.message}
          onSaveVisibleStatus={handleClose}
          visible={visible}
        />
      ) : (
        !error &&
        visible && (
          <SuccessNotification
            title={notification.title}
            message={notification.message}
            onSaveVisibleStatus={handleClose}
            visible={visible}
          />
        )
      )}
      <div className="bg-zinc-100 w-full">
        <div className="container">
          <form
            onSubmit={handleSubmit}
            className="bg-white py-4 px-12 shadow-md mt-5"
          >
            <label htmlFor="title">Titulo</label>
            <input
              type="text"
              className="inputBox !text-md font-semibold"
              placeholder="Titulo"
              ref={titleRef}
              defaultValue={postData.title}
            />
            <input
              type="file"
              className="inputBox !text-md font-semibold"
              placeholder="Titulo"
              accept="image/*"
              ref={featuredImgRef}
            />
            <label>Descripción</label>
            <textarea
              type="text"
              className="inputBox !text-md font-semibold"
              placeholder="Descripcion"
              ref={descriptionRef}
              defaultValue={postData.description}
            ></textarea>
            <div className="my-4">
              <label htmlFor="content" className="mt-32">
                Contenido
              </label>
              <RichTextEditor
                value={value}
                onChange={onChange}
                className="w-full mb-5"
                onImageUpload={handleImageUpload}
              />
            </div>
            <label htmlFor="categories">Categorias</label>
            {renderSelect(postData.categories)}
            <div className="w-full flex justify-center">
              <button
                className="rounded-lg ml-2 px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 duration-300 w-32"
                type="submit"
              >
                Editar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditBlog;
