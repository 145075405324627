import { Routes, Route, Navigate } from 'react-router-dom'
import { AuthProvider, useAuth } from './contexts/Auth'
import ManufacturingForm from './components/ManufacturingForm';
import Signup from './components/Signup';
import Login from './components/Login';
import React from 'react'
import Dashboard from './components/Dashboard';
function App() {
  const { user } = useAuth()

  return (
    
    <Routes>
      <Route path="*" element={user ? (<Dashboard />) : (<Navigate to="/login" />)} />
      <Route path="/signup" element={!user ? (<Signup />) : (<Navigate to="/" />)} />
      <Route path="/login" element={!user ? (<Login />) : (<Navigate to="/" />)} />
    </Routes>
  );
}

export default App;
