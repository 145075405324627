import React from "react";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
const ApplicationsForm = () => {
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "applications",
  });
  return (
    <div className="mt-2">
      <h1 className="text-xl font-semibold">Otras entradas</h1>
      <ul>
        {fields.map((item, index) => {
          return (
            <div className="flex flex-col mb-1" key={item.id}>
              <div className="flex flex-row items-center">
                <h2 className="font-semibold text-lg basis-3/4">
                  Entrada {index + 1}
                </h2>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="basis-1/4 w-fit mb-2 rounded-lg px-4 py-2 bg-red-500 text-white hover:bg-red-600 duration-300"
                >
                  Remover entrada
                </button>
              </div>
              <input
                {...register(`applications.${index}.entryImg`)}
                className="inputBox"
                placeholder="Imagen de la entrada"
              />
              <input
                {...register(`applications.${index}.entryTitle`)}
                className="inputBox"
                placeholder="Titulo de la entrada"
              />
              <textarea
                {...register(`applications.${index}.entryDesc`)}
                className="inputBox"
                placeholder="Descripcion de la entrada"
                cols="30"
                rows="5"
              ></textarea>
            </div>
          );
        })}
      </ul>
      <section>
        <button
          type="button"
          className="rounded-lg px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 duration-300 w-full"
          onClick={() => {
            append({});
          }}
        >
          Agregar entrada
        </button>
      </section>
    </div>
  );
};

export default ApplicationsForm;
