import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/Auth";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

const Sidebar = () => {
  const [materialsNumber, setMaterialsNumber] = useState(0);
  const [processesNumber, setProcessesNumber] = useState(0);
  const [postsNumber, setPostsNumber] = useState(0);
  const [materialItem, setMaterialItem] = useState("text-gray-600");
  const [processItem, setProcessItem] = useState("text-gray-600");
  const [blogItem, setBlogItem] = useState("text-gray-600");
  const [index, setIndex] = useState("text-gray-300");
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate("/login", { replace: true });
  };

  const countMaterials = async () => {
    const { data: materials, count } = await supabase
      .from("materials")
      .select("*", { count: "exact" });
    setMaterialsNumber(count);
  };

  const countProcesses = async () => {
    const { data: processes, count } = await supabase
      .from("manufacturing")
      .select("*", { count: "exact" });
    setProcessesNumber(count);
  };

  const countPosts = async () => {
    const { data: posts, count } = await supabase
      .from("blog")
      .select("*", { count: "exact" });
    setPostsNumber(count);
  };

  useEffect(() => {
    countMaterials();
    countProcesses();
    countPosts();
  }, []);

  return (
    <div className="flex flex-no-wrap bg-gray-800">
      {/* Sidebar starts */}
      {/* Remove class [ hidden ] and replace [ sm:flex ] with [ flex ] */}
      <div className="w-64 absolute sm:relative bg-gray-800 shadow md:h-full flex-col justify-between hidden sm:flex">
        <div className="px-8">
          <div className="h-16 w-full flex items-center pt-12">
            <img
              src="https://cdn.shopify.com/s/files/1/0405/7746/6523/files/Logo-3d-factory-mx-con-R_195x@2x.png"
              alt=""
            />
          </div>
          <ul className="mt-12">
            <li
              className={`flex w-full justify-between ${index} hover:text-gray-500 cursor-pointer items-center mb-6`}
              onClick={() => {
                setIndex("text-gray-300");
                setMaterialItem("text-gray-600");
                setProcessItem("text-gray-600");
                setBlogItem("text-gray-600");
              }}
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-grid"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x={4} y={4} width={6} height={6} rx={1} />
                  <rect x={14} y={4} width={6} height={6} rx={1} />
                  <rect x={4} y={14} width={6} height={6} rx={1} />
                  <rect x={14} y={14} width={6} height={6} rx={1} />
                </svg>
                <Link to="/">
                  <span className="text-sm  ml-2">Inicio</span>
                </Link>
              </div>
            </li>
            <li
              className={`flex w-full justify-between ${materialItem} hover:text-gray-500 cursor-pointer items-center mb-6`}
              onClick={() => {
                setIndex("text-gray-600");
                setMaterialItem("text-gray-300");
                setProcessItem("text-gray-600");
                setBlogItem("text-gray-600");
              }}
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-puzzle"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
                </svg>
                <Link to="/materials">
                  <span className="text-sm  ml-2">Materiales</span>
                </Link>
              </div>
              <div className="py-1 px-3 bg-gray-700 rounded text-gray-500 flex items-center justify-center text-xs">
                {materialsNumber}
              </div>
            </li>
            <li
              className={`flex w-full justify-between ${processItem} hover:text-gray-500 cursor-pointer items-center mb-6`}
              onClick={() => {
                setIndex("text-gray-600");
                setMaterialItem("text-gray-600");
                setProcessItem("text-gray-300");
                setBlogItem("text-gray-600");
              }}
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-puzzle"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
                </svg>
                <Link to="/manufacturing">
                  <span className="text-sm  ml-2">Procesos de manufactura</span>
                </Link>
              </div>
              <div className="py-1 px-3 bg-gray-700 rounded text-gray-500 flex items-center justify-center text-xs">
                {processesNumber}
              </div>
            </li>
            <li
              className={`${blogItem} flex w-full justify-between text-gray-600 hover:text-gray-500 cursor-pointer items-center`}
              onClick={() => {
                setIndex("text-gray-600");
                setMaterialItem("text-gray-600");
                setProcessItem("text-gray-600");
                setBlogItem("text-gray-300");
              }}
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-settings"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <circle cx={12} cy={12} r={3} />
                </svg>
                <Link to="/blog">
                  <span className="text-sm  ml-2">Blog</span>
                </Link>
              </div>
              <div className="py-1 px-3 bg-gray-700 rounded text-gray-500 flex items-center justify-center text-xs">
                {postsNumber}
              </div>
            </li>
          </ul>
          <button
            type="button"
            onClick={handleSignOut}
            className="rounded-sm text-center p-2 bg-red-600 text-white mt-[300%] mb-4 w-full hover:bg-red-700"
          >
            Cerrar sesion
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
