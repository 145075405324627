import React from "react";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";

const ListForm = () => {
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "list",
  });
  return (
    <div className="mt-2">
      <ul>
        {fields.map((item, index) => {
          return (
            <div className="flex flex-row mb-1" key={item.id}>
              <input
                {...register(`featuredApplications.list.${index}`)}
                className="inputBox"
                placeholder="Aplicacion"
              />
              <button
                type="button"
                onClick={() => remove(index)}
                className="rounded-lg ml-2 px-4 py-2 bg-red-500 text-white hover:bg-red-600 duration-300 w-32"
              >
                Remover aplicacion
              </button>
            </div>
          );
        })}
      </ul>
      <section>
        <button
          type="button"
          className="rounded-lg px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 duration-300 w-full"
          onClick={() => {
            append({});
          }}
        >
          Agregar aplicacion en entrada destacada
        </button>
      </section>
    </div>
  );
};

export default ListForm;
