import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { supabase } from "../supabaseClient";
const EditMaterial = () => {
  const { register, handleSubmit, reset } = useForm({});
  const [material, setMaterial] = useState([]);
  // const [updatedFamily, setUpdatedFamily] = useState([{}]);
  const { id } = useParams();

  const fetchMaterial = async () => {
    try {
      let { data: materials } = await supabase
        .from("materials")
        .select(`*`)
        .eq("id", id);
      setMaterial(materials[0]);
      reset(materials[0]);
    } catch (e) {
      setMaterial([]);
    }
  };

  const onSubmit = async (data) => {
    if (JSON.stringify(data) === JSON.stringify(material))
      alert("No se realizaron cambios.");
    else {
      let { data: materials_data, error } = await supabase
        .from("materials")
        .update(data)
        .match({ id: data.id });
      error
        ? alert("Hubo un error al actualizar. Intentalo de nuevo.")
        : alert("Actualizacion exitosa");
    }
  };

  useEffect(() => {
    fetchMaterial();
  }, []);

  const colors =
    material.colors &&
    material.colors.map((color, index) => (
      <input
        type="color"
        defaultValue={color}
        {...register(`colors.${index}`)}
      />
    ));

  const family =
    material.family &&
    material.family.map((family, familyIndex) => (
      <div key={familyIndex} className="mt-5">
        <h3 className="font-semibold text-lg">Material {familyIndex + 1}</h3>
        <label htmlFor="familyTitle" className="labelContent">
          Titulo
        </label>
        <input
          type="text"
          name="familyTitle"
          id="familyTitle"
          className="inputBox"
          defaultValue={family.title}
          {...register(`family.${familyIndex}.title`)}
        />
        <label htmlFor="familySubtitle" className="labelContent">
          Subtitulo
        </label>
        <input
          type="text"
          name="familySubtitle"
          id="familyTitle"
          className="inputBox"
          defaultValue={family.subtitle}
          {...register(`family.${familyIndex}.subtitle`)}
        />
        <label htmlFor="familyDescription" className="labelContent">
          Descripción
        </label>
        <textarea
          type="text"
          name="familyTitle"
          id="familyTitle"
          className="inputBox"
          rows="5"
          cols="5"
          defaultValue={family.description}
          {...register(`family.${familyIndex}.description`)}
        ></textarea>
        <label htmlFor="features" className="labelContent">
          Características
        </label>
        {family.features.map((feature, index) => (
          <input
            defaultValue={feature}
            className="inputBox"
            {...register(`family.${familyIndex}.features.${index}`)}
          />
        ))}
        <h2 className="font-semibold text-md mt-5">Metricas</h2>
        <div className="flex flex-row">
          <div className="flex flex-col w-1/4 mx-1">
            {family.metricTitle.map((title, index) => (
              <>
                <h2 className="labelContent">Titulo</h2>
                <input
                  defaultValue={title}
                  className="inputBox"
                  {...register(`family.${familyIndex}.metricTitle.${index}`)}
                />
              </>
            ))}
          </div>
          <div className="flex flex-col w-1/4 mx-1">
            {family.metricMinIndex.map((minIndex, index) => (
              <>
                <h2 className="labelContent">Indice menor</h2>
                <input
                  defaultValue={minIndex}
                  className="inputBox"
                  {...register(`family.${familyIndex}.metricMinIndex.${index}`)}
                />
              </>
            ))}
          </div>
          <div className="flex flex-col w-1/4 mx-1">
            {family.metricPercent.map((metricPercent, index) => (
              <>
                <h2 className="labelContent">Porcentaje</h2>
                <input
                  defaultValue={metricPercent}
                  className="inputBox"
                  {...register(`family.${familyIndex}.metricPercent.${index}`)}
                />
              </>
            ))}
          </div>
          <div className="flex flex-col w-1/4 mx-1">
            {family.metricMaxIndex.map((maxIndex, index) => (
              <>
                <h2 className="labelContent">Indice mayor</h2>
                <input
                  defaultValue={maxIndex}
                  className="inputBox"
                  {...register(`family.${familyIndex}.metricMaxIndex.${index}`)}
                />
              </>
            ))}
          </div>
        </div>
        <label htmlFor="gallery" className="labelContent">
          Galeria
        </label>
        {family.gallery.map((img, index) => (
          <input defaultValue={img} className="inputBox" />
        ))}
      </div>
    ));

  const specifications =
    material.specifications &&
    material.specifications.map((spec, index) => (
      <div>
        <h3 className="font-semibold text-lg">Columna {index + 1}</h3>
        <label htmlFor="title" className="labelContent">
          Titulo
        </label>
        <input
          type="text"
          name="specTitle"
          id="specTitle"
          className="inputBox"
          defaultValue={spec.title}
        />
        <div className="flex flex-row">
          <div className="flex flex-col w-1/4 mx-1">
            {spec.propTitle.map((title) => (
              <>
                <label htmlFor="propTitle" className="labelContent">
                  Titulo de la propiedad
                </label>
                <input
                  type="text"
                  name="propTitle"
                  id="prop"
                  className="inputBox"
                  defaultValue={title}
                />
              </>
            ))}
          </div>
          <div className="flex flex-col w-3/4">
            {spec.propDesc.map((desc) => (
              <>
                <label htmlFor="propTitle" className="labelContent">
                  Descripcion de la propiedad
                </label>
                <textarea
                  type="text"
                  name="propDesc"
                  id="prop"
                  className="inputBox"
                  defaultValue={desc}
                  row="10"
                ></textarea>
              </>
            ))}
          </div>
        </div>
      </div>
    ));

  const featuredApplications = material.featuredApplications && (
    <>
      <label htmlFor="featuredImg" className="labelContent">
        Imagen destacada
      </label>
      <input
        type="text"
        name="featuredImg"
        id="featuredImg"
        className="inputBox"
        defaultValue={material.featuredApplications.featuredImg}
        {...register(`featuredApplications.featuredImg`)}
      />
      <label htmlFor="featuredText" className="labelContent">
        Texto destacado
      </label>
      <textarea
        type="text"
        name="featuredText"
        id="featuredText"
        className="inputBox"
        defaultValue={material.featuredApplications.featuredText}
        {...register(`featuredApplications.featuredText`)}
        row="5"
      ></textarea>
      <label htmlFor="list" className="labelContent">
        Lista de aplicaciones destacadas
      </label>
      {material.featuredApplications.list.map((item, index) => (
        <input
          type="text"
          name="application"
          className="inputBox"
          defaultValue={item}
          {...register(`featuredApplications.list.${index}`)}
        />
      ))}
    </>
  );

  const applications = material.applications && (
    <>
      {material.applications.map((app, appIndex) => (
        <div className="mt-5">
          <h2 className="font-semibold text-md">Entrada {appIndex + 1}</h2>
          <label htmlFor="entryImg" className="labelContent">
            Imagen de la entrada {appIndex + 1}
          </label>
          <input
            type="text"
            className="inputBox"
            defaultValue={app.entryImg}
            {...register(`applications.${appIndex}.entryImg`)}
          />
          <label htmlFor="entryTitle" className="labelContent">
            Titulo de la entrada {appIndex + 1}
          </label>
          <input
            type="text"
            className="inputBox"
            defaultValue={app.entryTitle}
            {...register(`applications.${appIndex}.entryTitle`)}
          />
          <label htmlFor="entryDesc" className="labelContent">
            Descripcion de la entrada {appIndex + 1}
          </label>
          <textarea
            type="text"
            className="inputBox"
            row="5"
            defaultValue={app.entryDesc}
            {...register(`applications.${appIndex}.entryDesc`)}
          ></textarea>
        </div>
      ))}
    </>
  );

  return (
    <div className="w-full m-5">
      <h2 className="text-2xl font-semibold text-center">Editar material</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div id="material" className="flex flex-col">
          <label htmlFor="materialName" className="labelContent mt-2">
            Nombre del material
          </label>
          <input
            type="text"
            className="inputBox"
            defaultValue={material.name}
            {...register(`name`)}
          />
          <label htmlFor="materialDescription" className="labelContent mt-2">
            Descripcion del material
          </label>
          <textarea
            className="inputBox"
            name="description"
            id=""
            cols="30"
            rows="10"
            defaultValue={material.description}
            {...register(`description`)}
          ></textarea>
          <label htmlFor="materialImg" className="labelContent">
            Imagen del material
          </label>
          <input
            className="inputBox"
            type="text"
            name="materialImg"
            id="materialImg"
            defaultValue={material.img}
            {...register(`img`)}
          />
          <label htmlFor="colors">Colores</label>
          {colors}
        </div>

        <div className="flex flex-col mt-5" id="family">
          <h2 className="font-semibold text-lg">Materiales en la familia</h2>
          {family}
        </div>
        <div className="flex flex-col mt-5" id="specs">
          <h2 className="font-semibold text-lg">Especificaciones</h2>
          {specifications}
        </div>
        <div className="flex flex-col mt-5" id="specs">
          <h2 className="font-semibold text-lg">Aplicaciones</h2>
          {featuredApplications}
          {applications}
        </div>
        <button type="submit">Guardar</button>
      </form>
    </div>
  );
};

export default EditMaterial;
