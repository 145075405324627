import React from "react";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import PropertiesInput from "./PropertiesInput";

const SpecificationsForm = () => {
  const { register, control } = useFormContext();
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "materials",
    name: "specifications",
  });
  return (
    <div id="family_form">
      <h1 className="text-2xl font-semibold">Especificaciones</h1>
      <ul>
        {fields.map((item, index) => {
          return (
            <div className="flex flex-col mb-1" key={item.id}>
              <div className="flex flex-row items-center">
                <h2 className="font-semibold text-lg basis-3/4">
                  Columna {index + 1}
                </h2>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="basis-1/4 w-fit mb-2 rounded-lg px-4 py-2 bg-red-500 text-white hover:bg-red-600 duration-300"
                >
                  Remover columna
                </button>
              </div>
              <li className="flex flex-row">
                <input
                  {...register(`specifications.${index}.title`)}
                  className="inputBox"
                  placeholder="Titulo de la columna"
                />
              </li>
              <PropertiesInput name={`properties${index}`} index={index} />
            </div>
          );
        })}
      </ul>
      <section>
        <button
          type="button"
          className="rounded-lg px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 duration-300 w-full"
          onClick={() => {
            append({});
          }}
        >
          Agregar columna
        </button>
      </section>
    </div>
  );
};

export default SpecificationsForm;
