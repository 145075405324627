import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import EditMaterial from "./EditMaterial";
import EditProcess from "./EditProcess";
import Manufacturing from "./Manufacturing";
import ManufacturingForm from "./ManufacturingForm";
import Materials from "./Materials";
import MaterialsForm from "./MaterialsForm";
import Sidebar from "./Sidebar";
import Blog from "./Blog";
import AddBlogEntry from "./AddBlogEntry";
import EditBlog from "./EditBlog";
import ManageCategories from "./ManageCategories";

const Dashboard = () => {
  useEffect(() => {
    document.title = "Panel de administrador - 3D Factory MX";
  }, []);
  return (
    <div className="flex">
      <aside className="h-screen sticky top-0">
        <Sidebar />
      </aside>
      <Routes>
        <Route path="/materials" element={<Materials />} />
        <Route path="/materials/add" element={<MaterialsForm />} />
        <Route path="/materials/edit/:id" element={<EditMaterial />} />
        <Route path="/manufacturing" element={<Manufacturing />} />
        <Route path="/manufacturing/add" element={<ManufacturingForm />} />
        <Route path="/manufacturing/edit/:id" element={<EditProcess />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/add" element={<AddBlogEntry />} />
        <Route path="/blog/edit/:id" element={<EditBlog />} />
        <Route path="/blog/categories/manage" element={<ManageCategories />} />
      </Routes>
      {/* <ManufacturingForm /> */}
    </div>
  );
};

export default Dashboard;
