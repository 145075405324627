import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RichTextEditor } from "@mantine/rte";
import { supabase } from "../supabaseClient";
import Select from "react-select";
import ErrorNotification from "./ErrorNotification";
import SuccessNotification from "./SuccessNotification";

const initialValue = "";
const AddBlogEntry = () => {
  const navigate = useNavigate();
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const featuredImgRef = useRef(null);
  const { v4: uuidv4 } = require("uuid");
  const [value, onChange] = useState(initialValue);
  const [categories, setCategories] = useState([]);
  const [postCategories, setPostCategories] = useState([]);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
  });
  const fetchCategories = async () => {
    const { data, error } = await supabase
      .from("blog_categories")
      .select("label, value");
    setCategories(data);
    console.log(categories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryChange = (value) => {
    setPostCategories(value);
  };

  const handleImageUpload = (file) =>
    new Promise(async (resolve, reject) => {
      const newName = uuidv4();
      const extension = file.name.split(".").pop();
      const newFileName = `${newName}.${extension}`;
      const { data, error } = await supabase.storage
        .from("blog")
        .upload(`images/${newFileName}`, file, {
          cacheControl: "3600",
          upsert: false,
        });
      const { publicURL, url_error } = supabase.storage
        .from("blog")
        .getPublicUrl(`images/${newFileName}`);
      resolve(publicURL);
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let today = new Date();
    today = today.toLocaleString("es-MX", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    const newName = uuidv4();
    const featuredImg = featuredImgRef.current.files[0];
    const extension = featuredImg.name.split(".").pop();
    const newFileName = `featured_${newName}.${extension}`;
    const { img_data, img_error } = await supabase.storage
      .from("blog")
      .upload(`images/${newFileName}`, featuredImg, {
        cacheControl: "3600",
        upsert: false,
      });

    const { publicURL, url_error } = supabase.storage
      .from("blog")
      .getPublicUrl(`images/${newFileName}`);

    const { data, error } = await supabase.from("blog").insert([
      {
        title: titleRef.current.value,
        description: descriptionRef.current.value,
        content: value,
        releaseDate: today,
        categories: postCategories,
        editDate: "",
        entry_img: publicURL,
      },
    ]);

    if (error === null) {
      setError(false);
      setNotification({
        title: "Éxito",
        message: "Tu artículo se publico con éxito.",
      });
      setVisible(true);
      navigate("/blog");
    } else {
      setError(true);
      setNotification({
        title: "Fallo",
        message: `Tu artículo no se ha podido publicar. Intentalo de nuevo. Mensaje de error: ${error.message}`,
      });
      setVisible(true);
    }
  };

  const handleClose = (closeStatus) => {
    setVisible(closeStatus);
  };
  return (
    <>
      {error && visible ? (
        <ErrorNotification
          title={notification.title}
          message={notification.message}
          onSaveVisibleStatus={handleClose}
          visible={visible}
        />
      ) : (
        !error &&
        visible && (
          <SuccessNotification
            title={notification.title}
            message={notification.message}
            onSaveVisibleStatus={handleClose}
            visible={visible}
          />
        )
      )}
      <div className="bg-zinc-100 w-full">
        <div className="container">
          <form
            onSubmit={handleSubmit}
            className="bg-white py-4 px-12 shadow-md mt-5"
          >
            <label htmlFor="title">Titulo</label>
            <input
              type="text"
              className="inputBox !text-md font-semibold"
              placeholder="Titulo"
              ref={titleRef}
            />
            <label htmlFor="title">Imagen destacada</label>
            <div className="flex flex-row">
              <input
                type="file"
                className="inputBox !text-md font-semibold"
                placeholder="Titulo"
                accept="image/*"
                ref={featuredImgRef}
                required
              />
            </div>
            <label htmlFor="description">Descripción</label>
            <textarea
              type="text"
              className="inputBox !text-md font-medium"
              ref={descriptionRef}
              required
            ></textarea>
            <div className="my-4">
              <label htmlFor="content" className="mt-32">
                Contenido
              </label>
              <RichTextEditor
                value={value}
                onChange={onChange}
                className="w-full mb-5"
                onImageUpload={handleImageUpload}
              />
            </div>
            <label htmlFor="categories">Categorias</label>
            <Select
              options={categories}
              name="categories"
              isMulti
              className="mb-10"
              onChange={handleCategoryChange}
            />
            <div className="w-full flex justify-center">
              <button
                className="rounded-lg ml-2 px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 duration-300 w-32"
                type="submit"
              >
                Publicar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddBlogEntry;
