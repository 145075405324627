import RichTextEditor from "@mantine/rte";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../supabaseClient";
import ErrorNotification from "./ErrorNotification";
import SuccessNotification from "./SuccessNotification";
const EditProcess = () => {
  const [moreInfoTitle, setMoreInfoTitle] = useState("");
  const [moreInfoContent, setMoreInfoContent] = useState("");
  const [otherBenefitsTitle, setOtherBenefitsTitle] = useState("");
  const [otherBenefitsContent, setOtherBenefitsContent] = useState("");
  const [otherUsesTitle, setOtherUsesTitle] = useState("");
  const [otherUsesContent, setOtherUsesContent] = useState("");
  const [newEntry, setNewEntry] = useState("");
  const [entryContent, setEntryContent] = useState("");
  const [process, setProcess] = useState({ loading: true, data: [] });
  const [newProcess, setNewProcess] = useState({});
  const [application, setApplication] = useState([]);
  const [guidelinesObject, setGuidelinesObject] = useState([]);
  const [materialsName, setMaterialsName] = useState([]);
  const [materialsContent, setMaterialsContent] = useState([]);
  const [benefits, setBenefits] = useState([[]]);
  const [comparisonHeader, setComparisonHeader] = useState([]);
  const [comparisonContent, setComparisonContent] = useState([]);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
  });
  const { id } = useParams();

  useEffect(() => {
    fetchProcess();
    process.loading
      ? (document.title = "")
      : (document.title =
          "Editar " + process.data[0].name + " - 3D Factory MX");
    !process.loading
      ? setGuidelinesObject(process.data[0].guidelinesSections)
      : setGuidelinesObject([]);
    !process.loading
      ? setApplication(process.data[0].applications)
      : setApplication([]);
    !process.loading
      ? setMaterialsName(process.data[0].typeTitle)
      : setMaterialsName([]);
    !process.loading
      ? setMaterialsContent(process.data[0].typeText)
      : setMaterialsContent([]);
    !process.loading
      ? setBenefits(process.data[0].typeBenefitsList)
      : setBenefits([]);
    !process.loading
      ? setComparisonHeader(process.data[0].comparisonTH)
      : setComparisonHeader([]);
    !process.loading
      ? setComparisonContent(process.data[0].comparisonTB)
      : setComparisonContent([]);
    !process.loading ? setNewEntry(process.data[0].entry) : setNewEntry("");
    !process.loading
      ? setEntryContent(process.data[0].content)
      : setEntryContent("");
    !process.loading
      ? setOtherBenefitsContent(process.data[0].other_benefits_content)
      : setOtherBenefitsContent("");
    !process.loading
      ? setOtherUsesContent(process.data[0].other_uses_content)
      : setOtherUsesContent("");
    !process.loading
      ? setMoreInfoContent(process.data[0].moreInfoContent)
      : setMoreInfoContent("");
  }, [process.loading]);

  const fetchProcess = async () => {
    let { data: manufacturing } = await supabase
      .from("manufacturing")
      .select(`*`)
      .eq("id", id);
    setProcess({ loading: false, data: manufacturing });
  };

  const handleApplications = (e, index) => {
    let newArray = [];
    newArray = [...application];
    newArray[index] = e.target.value;
    setApplication(newArray);
    return newArray;
  };

  const handleGuidelines = (
    e,
    sectionIndex,
    colIndex,
    colType,
    type,
    typeOfContent
  ) => {
    let guidelines = [];
    let colArr = [];
    guidelines = [...guidelinesObject];
    if (typeOfContent === "name") {
      guidelines[sectionIndex] = {
        ...guidelines[sectionIndex],
        name: e.target.value,
      };
    }
    if (colType === "header") {
      colArr = [...guidelinesObject];
      colArr[sectionIndex].headerCol[colIndex] = e.target.value;
      guidelines[sectionIndex] = {
        ...guidelines[sectionIndex],
        headerCol: colArr[sectionIndex].headerCol,
      };
    } else if (colType === "content") {
      colArr = [...guidelinesObject];
      colArr[sectionIndex].colContent[colIndex] = e.target.value;
      guidelines[sectionIndex] = {
        ...guidelines[sectionIndex],
        colContent: colArr[sectionIndex].colContent,
      };
    }

    if (type === "DESCRIPTION" && typeOfContent === "title") {
      guidelines[sectionIndex] = {
        ...guidelines[sectionIndex],
        title: e.target.value,
      };
    } else if (type === "DESCRIPTION" && typeOfContent === "text") {
      guidelines[sectionIndex] = {
        ...guidelines[sectionIndex],
        text: e.target.value,
      };
    }
    setGuidelinesObject(guidelines);
    setNewProcess({ ...newProcess, guidelinesSections: guidelines });
  };

  const handleMaterials = (e, index, type, benefitNumber) => {
    let arr = [];
    if (type === "title") {
      arr = [...materialsName];
      arr[index] = e.target.value;
      setMaterialsName(arr);
      setNewProcess({ ...newProcess, typeTitle: arr });
    } else if (type === "text") {
      arr = [...materialsContent];
      arr[index] = e.target.value;
      setMaterialsContent(arr);
      setNewProcess({ ...newProcess, typeText: arr });
    } else if (type === "benefits") {
      arr = [...benefits];
      if (benefitNumber === 1) arr[index][0] = e.target.value;
      else arr[index][1] = e.target.value;
      setBenefits(arr);
      setNewProcess({ ...newProcess, typeBenefitsList: arr });
    }
  };

  const handleComparison = (e, index, contentIndex, type) => {
    let arr = [];
    if (type === "header") {
      arr = [...comparisonHeader];
      arr[index] = e.target.value;
      setComparisonHeader(arr);
      setNewProcess({ ...newProcess, comparisonTH: arr });
    }

    if (type === "content") {
      arr = [...comparisonContent];
      arr[index][contentIndex] = e.target.value;
      setComparisonContent(arr);
      setNewProcess({ ...newProcess, comparisonTB: arr });
    }
  };

  const handleSubmit = async () => {
    if (newProcess.length != 0) {
      const { data, error } = await supabase
        .from("manufacturing")
        .update({
          ...newProcess,
          entry: newEntry,
          content: entryContent,
          other_benefits_title: otherBenefitsTitle,
          other_benefits_content: otherBenefitsContent,
          other_uses_title: otherUsesTitle,
          other_uses_content: otherUsesContent,
          more_info_title: moreInfoTitle,
          more_info_content: moreInfoContent,
        })
        .match({ id: id });
      console.log(data, error);
      if (data) {
        setError(false);
        setNotification({
          title: "Éxito",
          message: "El proceso se editó con éxito.",
        });
        setVisible(true);
      } else {
        setError(true);
        setNotification({
          title: "Fallo",
          message: `El proceso no se ha podido editar. Intentalo de nuevo. Mensaje de error: ${error.message}`,
        });
        setVisible(true);
      }
    }
  };

  const handleClose = (closeStatus) => {
    setVisible(closeStatus);
  };

  return (
    <>
      {error && visible ? (
        <ErrorNotification
          title={notification.title}
          message={notification.message}
          onSaveVisibleStatus={handleClose}
          visible={visible}
        />
      ) : (
        !error &&
        visible && (
          <SuccessNotification
            title={notification.title}
            message={notification.message}
            onSaveVisibleStatus={handleClose}
            visible={visible}
          />
        )
      )}
      {!process.loading && (
        <div className="w-full py-10 px-6">
          <div className="flex flex-col w-full">
            <h3 className="text-lg font-semibold mb-10">
              Editar {process.data[0].name}
            </h3>
            <form
              className="flex flex-col"
              onSubmit={(event) => event.preventDefault()}
            >
              <div className="flex flex-row mb-4">
                <span className="font-semibold text-sm w-48">
                  Nombre del proceso
                </span>
                <input
                  type="text"
                  className="inputBox !py-1"
                  defaultValue={process.data[0].name}
                  onChange={(e) =>
                    setNewProcess({ ...newProcess, name: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row mb-4">
                <div className="font-semibold text-sm w-48">
                  Imagen del proceso
                </div>
                <input
                  type="text"
                  className="inputBox !py-1 "
                  defaultValue={process.data[0].img}
                  onChange={(e) =>
                    setNewProcess({ ...newProcess, img: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row mb-4">
                <span className="font-semibold text-sm w-48">Entrada</span>
                <RichTextEditor value={newEntry} onChange={setNewEntry} />
              </div>
              <div className="flex flex-row mb-4">
                <div className="font-semibold text-sm w-48">
                  Imagen de la entrada del proceso
                </div>
                <input
                  type="text"
                  className="inputBox !py-1 "
                  defaultValue={process.data[0].entryImg}
                  onChange={(e) =>
                    setNewProcess({ ...newProcess, entryImg: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row mb-4">
                <span className="font-semibold text-sm w-48">Contenido</span>
                <RichTextEditor
                  value={entryContent}
                  onChange={setEntryContent}
                />
              </div>
              <div className="flex flex-row mb-4">
                <h2 className="font-semibold text-sm w-48">Aplicaciones</h2>
                <div className="flex flex-col w-full">
                  {process.data[0].applications.map((application, index) => {
                    return (
                      <input
                        type="text"
                        className="inputBox !py-1"
                        defaultValue={application}
                        onChange={(e) =>
                          setNewProcess({
                            ...newProcess,
                            applications: [
                              ...handleApplications(
                                e,
                                index,
                                process.data[0].applications.length
                              ),
                            ],
                          })
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-row mb-4">
                <h2 className="font-semibold text-sm w-48">Url del video</h2>
                <input
                  type="text"
                  className="inputBox !py-1 "
                  defaultValue={process.data[0].video}
                  onChange={(e) =>
                    setNewProcess({ ...newProcess, video: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row mb-4">
                <h2 className="font-semibold text-sm w-48">
                  Título de la sección con vídeo
                </h2>
                <input
                  type="text"
                  className="inputBox !py-1 "
                  defaultValue={process.data[0].videoTitle}
                  onChange={(e) =>
                    setNewProcess({ ...newProcess, videoTitle: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row mb-4">
                <h2 className="font-semibold text-sm w-48">
                  Texto de la sección con vídeo
                </h2>
                <textarea
                  type="text"
                  className="inputBox !py-1 !h-32"
                  defaultValue={process.data[0].videoText}
                  onChange={(e) =>
                    setNewProcess({ ...newProcess, videoText: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="flex flex-row mb-4">
                <h2 className="font-semibold text-sm w-48">
                  Titulo de la sección de directrices
                </h2>
                <input
                  type="text"
                  className="inputBox !py-1 "
                  defaultValue={process.data[0].guidelineTitle}
                  onChange={(e) =>
                    setNewProcess({
                      ...newProcess,
                      guidelineTitle: e.target.value,
                    })
                  }
                />
              </div>

              <div className="flex flex-row mb-4">
                <div className="flex-col w-1/2">
                  {process.data[0].guidelinesSections.map(
                    (item, sectionIndex) => {
                      if (item.type === "METRIC_TABLE")
                        return (
                          <div className="mr-3">
                            <h2 className="font-semibold text-sm w-48 inline pr-3">
                              Título de las métricas de la Tabla
                            </h2>
                            <input
                              type="text"
                              className="border-2 rounded-sm pl-2 text-sm"
                              defaultValue={item.name}
                              onChange={(e) =>
                                handleGuidelines(
                                  e,
                                  sectionIndex,
                                  null,
                                  null,
                                  null,
                                  "name"
                                )
                              }
                            />
                            {item.headerCol.map((col, colIndex) => {
                              return (
                                <input
                                  type="text"
                                  className="inputBox"
                                  defaultValue={col}
                                  onChange={(e) =>
                                    handleGuidelines(
                                      e,
                                      sectionIndex,
                                      colIndex,
                                      "header",
                                      null,
                                      null
                                    )
                                  }
                                />
                              );
                            })}
                          </div>
                        );
                    }
                  )}
                  {process.data[0].guidelinesSections.map(
                    (item, sectionIndex) => {
                      if (item.type === "OPTIONS_TABLE")
                        return (
                          <div className="mr-3">
                            <h2 className="font-semibold text-sm w-48 inline pr-3">
                              Título de las métricas de la Tabla
                            </h2>
                            <input
                              type="text"
                              className="border-2 rounded-sm pl-2 text-sm"
                              defaultValue={item.name}
                              onChange={(e) =>
                                handleGuidelines(
                                  e,
                                  sectionIndex,
                                  null,
                                  null,
                                  null,
                                  "name"
                                )
                              }
                            />
                            {item.headerCol.map((col, colIndex) => {
                              return (
                                <input
                                  type="text"
                                  className="inputBox !h-28"
                                  onChange={(e) =>
                                    handleGuidelines(
                                      e,
                                      sectionIndex,
                                      colIndex,
                                      "header",
                                      null,
                                      null
                                    )
                                  }
                                  defaultValue={col}
                                />
                              );
                            })}
                          </div>
                        );
                    }
                  )}
                </div>
                <div className="flex-col w-1/2">
                  {process.data[0].guidelinesSections.map((item) => {
                    if (item.type === "METRIC_TABLE")
                      return (
                        <div>
                          <h2 className="font-semibold text-sm w-48 inline">
                            Valor de las métricas de la Tabla {item.name}
                          </h2>
                          {item.colContent.map((col) => {
                            return (
                              <input
                                type="text"
                                className="inputBox"
                                defaultValue={col}
                              />
                            );
                          })}
                        </div>
                      );
                  })}
                  {process.data[0].guidelinesSections.map(
                    (item, sectionIndex) => {
                      if (item.type === "OPTIONS_TABLE")
                        return (
                          <div>
                            <h2 className="font-semibold text-sm w-48 inline pr-3">
                              Valor de las métrica de la Tabla {item.name}
                            </h2>

                            {item.colContent.map((col, colIndex) => {
                              return (
                                <textarea
                                  type="text"
                                  className="inputBox !h-28"
                                  defaultValue={col}
                                  onChange={(e) =>
                                    handleGuidelines(
                                      e,
                                      sectionIndex,
                                      colIndex,
                                      "content",
                                      null,
                                      null
                                    )
                                  }
                                ></textarea>
                              );
                            })}
                          </div>
                        );
                    }
                  )}
                </div>
              </div>
              {process.data[0].guidelinesSections.map((item, sectionIndex) => {
                if (item.type === "DESCRIPTION")
                  return (
                    <div className="flex flex-col mb-4">
                      <div className="flex flex-row">
                        <h2 className="font-semibold text-sm pr-2 inline w-24">
                          Titulo de{" "}
                        </h2>
                        <input
                          type="text"
                          className="text-sm !h-max border-2 mr-2 w-28 px-2"
                          defaultValue={item.name}
                          onChange={(e) =>
                            handleGuidelines(
                              e,
                              sectionIndex,
                              null,
                              null,
                              null,
                              "name"
                            )
                          }
                        />
                        <input
                          type="text"
                          className="inputBox !py-1 "
                          defaultValue={item.title}
                          onChange={(e) =>
                            handleGuidelines(
                              e,
                              sectionIndex,
                              null,
                              null,
                              "DESCRIPTION",
                              "title"
                            )
                          }
                        />
                      </div>
                      <div className="flex flex-row">
                        <h2 className="font-semibold text-sm w-48">
                          Contenido de {item.name}
                        </h2>
                        <textarea
                          type="text"
                          className="inputBox !py-1 !h-44"
                          defaultValue={item.text}
                          onChange={(e) =>
                            handleGuidelines(
                              e,
                              item,
                              null,
                              null,
                              item.type,
                              null,
                              "DESCRIPTION",
                              "text"
                            )
                          }
                        ></textarea>
                      </div>
                    </div>
                  );
              })}
              <div className="flex flex-row mb-4">
                <h2 className="font-semibold text-sm w-48">
                  Titulo de la sección de opciones del material
                </h2>
                <input
                  type="text"
                  className="inputBox "
                  defaultValue={process.data[0].optionsTitle}
                  onChange={(e) =>
                    setNewProcess({
                      ...newProcess,
                      optionsTitle: e.target.value,
                    })
                  }
                />
              </div>

              {process.data[0].typeTitle.map((title, index) => {
                return (
                  <div className="flex flex-col mb-4">
                    <div className="flex flex-row">
                      <h2 className="font-semibold text-sm w-48">
                        Nombre del material {index + 1}
                      </h2>
                      <input
                        type="text"
                        className="inputBox "
                        defaultValue={title}
                        onChange={(e) =>
                          handleMaterials(e, index, "title", null)
                        }
                      />
                    </div>
                    <div className="flex flex-row">
                      <h2 className="font-semibold text-sm w-48">
                        Contenido del material {index + 1}
                      </h2>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        className="inputBox !h-44"
                        defaultValue={process.data[0].typeText[index]}
                        onChange={(e) =>
                          handleMaterials(e, index, "text", null)
                        }
                      ></textarea>
                    </div>
                    <div className="flex flex-row">
                      <h2 className="font-semibold text-sm w-48">
                        Beneficio 1 del material {index + 1}
                      </h2>
                      <input
                        className="inputBox "
                        defaultValue={
                          process.data[0].typeBenefitsList[index][0]
                        }
                        onChange={(e) =>
                          handleMaterials(e, index, "benefits", 1)
                        }
                      />
                    </div>
                    <div className="flex flex-row">
                      <h2 className="font-semibold text-sm w-48">
                        Beneficio 2 del material {index + 1}
                      </h2>
                      <input
                        className="inputBox "
                        defaultValue={
                          process.data[0].typeBenefitsList[index][1]
                        }
                        onChange={(e) =>
                          handleMaterials(e, index, "benefits", 2)
                        }
                      />
                    </div>
                  </div>
                );
              })}
              <div className="flex flex-row">
                {process.data[0].comparisonTH.map((item, index) => {
                  return (
                    <div className="flex flex-col w-full">
                      <h2 className="font-semibold text-sm w-48">
                        Columna {index + 1}
                      </h2>
                      <input
                        type="text"
                        className="inputBox"
                        defaultValue={item}
                        onChange={(e) =>
                          handleComparison(e, index, null, "header")
                        }
                      />
                    </div>
                  );
                })}
              </div>
              {process.data[0].comparisonTB.map((row, index) => {
                return (
                  <div className="flex flex-row">
                    {row.map((input, contentIndex) => (
                      <input
                        type="text"
                        className="inputBox"
                        defaultValue={input}
                        onChange={(e) =>
                          handleComparison(e, index, contentIndex, "content")
                        }
                      />
                    ))}
                  </div>
                );
              })}
              {console.log(newProcess)}
              <h2 className="font-semibold text-xl">Otra información</h2>
              <div className="flex flex-row">
                <div className="benefits">
                  <label htmlFor="" className="labelContent">
                    Titulo
                  </label>
                  <input
                    type="text"
                    className="inputBox"
                    defaultValue={process.data[0].other_benefits_title}
                    onChange={(e) => setOtherBenefitsTitle(e.target.value)}
                  />
                  <RichTextEditor
                    value={otherBenefitsContent}
                    onChange={setOtherBenefitsContent}
                  />
                </div>
                <div className="uses">
                  <label htmlFor="" className="labelContent">
                    Titulo
                  </label>
                  <input
                    type="text"
                    className="inputBox"
                    defaultValue={process.data[0].other_uses_title}
                    onChange={(e) => setOtherUsesTitle(e.target.value)}
                  />
                  <RichTextEditor
                    value={otherUsesContent}
                    onChange={setOtherUsesContent}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="moreinfo">
                  <label htmlFor="" className="labelContent">
                    Titulo
                  </label>
                  <input
                    type="text"
                    className="inputBox"
                    defaultValue={process.data[0].more_info_title}
                    onChange={(e) => setMoreInfoTitle(e.target.value)}
                  />
                  <RichTextEditor
                    value={moreInfoContent}
                    onChange={setMoreInfoContent}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="px-4 py-2 mt-5 rounded-md bg-blue-500 text-white hover:bg-blue-700"
                onClick={() => handleSubmit()}
              >
                Editar proceso
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProcess;
