import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/Auth";

const Signup = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { signUp } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    const { error } = await signUp({ email, password });

    if (error) {
      alert("Error al iniciar sesión");
    } else {
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    document.title = "Registrarse - 3D Factory MX";
  }, []);

  return (
    <div className="flex w-full h-screen bg-blue-100">
      <div className="w-full max-w-xs m-auto">
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={handleSubmit}
        >
          <img
            src="https://cdn.shopify.com/s/files/1/0405/7746/6523/files/Logo-3d-factory-mx-con-R_195x@2x.png?v=1591294818"
            alt=""
          />
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Correo electrónico
            </label>
            <input
              className="shadow appearance-none border rounded w-full focus:border-blue-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Correo electrónico"
              ref={emailRef}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Contraseña
            </label>
            <input
              className="shadow appearance-none border focus:border-blue-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="******************"
              ref={passwordRef}
            />
          </div>
          <div className="flex items-center justify-center mb-6">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Registrarse
            </button>
          </div>
          <div className="flex items-center justify-center">
            <h3 className="text-sm">
              ¿Ya tienes una cuenta?
              <Link to={"/login"}>
                <span className="text-blue-500 font-medium hover:text-blue-700">
                  {" "}
                  Iniciar sesión
                </span>
              </Link>
            </h3>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
