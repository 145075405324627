import React from "react";
import { useFormContext } from "react-hook-form";
import { useForm, useFieldArray } from "react-hook-form";
const MetricsInput = (props) => {
  const { register, control } = useFormContext();
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: props.name,
  });
  return (
    <div>
      <ul>
        {fields.map((item, index) => {
          return (
            <div className="flex flex-row mb-1" key={item.id}>
              <input
                {...register(
                  `materials_in_family.${props.index}.metricTitle.${index}`,
                  { required: true }
                )}
                className="inputBox"
                placeholder="Titulo de la metrica"
              />
              <input
                {...register(
                  `materials_in_family.${props.index}.metricMinIndex.${index}`,
                  { required: true }
                )}
                className="inputBox"
                placeholder="Indice menor"
              />
              <input
                {...register(
                  `materials_in_family.${props.index}.metricPercent.${index}`,
                  { required: true }
                )}
                className="inputBox"
                placeholder="Porcentaje"
              />
              <input
                {...register(
                  `materials_in_family.${props.index}.metricMaxIndex.${index}`,
                  { required: true }
                )}
                className="inputBox"
                placeholder="Indice mayor"
              />
              <button
                type="button"
                onClick={() => remove(index)}
                className="rounded-lg ml-2 px-4 py-2 bg-red-500 text-white hover:bg-red-600 duration-300 w-full"
              >
                Remover metrica
              </button>
            </div>
          );
        })}
      </ul>
      <section>
        <button
          type="button"
          className="rounded-lg px-4 py-2 mb-8 mt-1 bg-indigo-500 text-white hover:bg-indigo-600 duration-300 w-full"
          onClick={() => {
            append({});
          }}
        >
          + Agregar metrica
        </button>
      </section>
    </div>
  );
};

export default MetricsInput;
